<template>
  <div>
    <filters-form @submit="filters = $event"/>

    <app-card>
      <template #body>
        <app-table request-url="/api/users/table" :fields="fields" :filters="filters">
          <template #cell(name)="data">
            <router-link :to="`users/${data.item.id}`" class="text-primary text-hover-success">
              {{ data.value }}
            </router-link>
          </template>
          <template #cell(username)="data">{{ data.value || '-' }}</template>
          <template #cell(phone)="data">{{ data.value || '-' }}</template>
          <template #cell(roles)="data">{{ data.value ? data.value.map(el => el.name).join(', ') : '' }}</template>
          <template #cell(person_id)="data">{{ `# ${data.value}` }}</template>
          <template #cell(actions)="data">
            <b-button variant="light-warning" size="sm" @click="resetPassword(data.item.id)">
              {{ $t('btn.reset_password') }}
            </b-button>
          </template>
        </app-table>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button variant="transparent-white" @click="$router.push({name: 'createUser'})">
        {{ $t('btn.create_user') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';
import FiltersForm from './components/FiltersForm';

export default {
  components: {
    AppCard,
    AppTable,
    FiltersForm
  },
  data() {
    return {
      filters: null,
      fields: [
        {key: 'name', label: this.$t('label.name')},
        {key: 'last_name', label: this.$t('label.last_name')},
        {key: 'username', label: this.$t('label.username')},
        {key: 'email', label: this.$t('label.email')},
        {key: 'phone', label: this.$t('label.phone')},
        {key: 'roles', label: this.$t('label.roles')},
        {key: 'person_id', label: this.$t('label.person_id')},
        {key: 'created_at', label: this.$t('label.created_at')},
        {key: 'actions', label: ''}
      ]
    }
  },
  methods: {
    resetPassword(id) {
      this.$store.dispatch('usersStore/RESET_PASSWORD', id);
    }
  }
}
</script>