<template>
  <app-filters :filters="form" @submit="$emit('submit', $event)">
    <template #body>
      <b-row>
        <b-col md="4">
          <app-input
              v-model="form.name"
              :label="$t('label.name')"
              :placeholder="$t('placeholder.enter_name')"
          />
        </b-col>
        <b-col md="4">
          <app-input
              v-model="form.last_name"
              :label="$t('label.last_name')"
              :placeholder="$t('placeholder.enter_last_name')"
          />
        </b-col>
        <b-col md="4">
          <app-input
              v-model="form.username"
              :label="$t('label.username')"
              :placeholder="$t('placeholder.enter_username')"
          />
        </b-col>
        <b-col md="4">
          <app-input
              v-model="form.email"
              :label="$t('label.email')"
              :placeholder="$t('placeholder.enter_email')"
          />
        </b-col>
        <b-col md="4">
          <app-multiselect
              :label="$t('label.team')"
              :placeholder="$t('placeholder.select_teams')"
              :value="teamsList.filter(el => form.team.includes(el.id))"
              :options="teamsList"
              item-label="name"
              multiple
              @select="form.team.push($event.id)"
              @remove="form.team = form.team.filter(el => el !== $event.id)"
          />
        </b-col>
        <b-col md="4">
          <app-input
              v-model="form.auth_id"
              :label="$t('label.auth_id')"
              :placeholder="$t('placeholder.enter_auth_id')"
          />
        </b-col>
        <b-col md="4">
          <app-input
              v-model="form.person_id"
              :label="$t('label.person_id')"
              :placeholder="$t('placeholder.enter_person_id')"
          />
        </b-col>
        <b-col md="4">
          <app-datepicker
              :label="$t('label.created_at')"
              :placeholder="$t('label.from')"
              :value="form.created_at_from"
              @change="form.created_at_from = $event"
          />
        </b-col>
        <b-col md="4">
          <app-datepicker
              :label="$t('label.created_at')"
              :placeholder="$t('label.to')"
              :value="form.created_at_to"
              @change="form.created_at_to = $event"
          />
        </b-col>
      </b-row>
    </template>
  </app-filters>
</template>

<script>
import {mapGetters} from 'vuex';
import AppFilters from '@/components/filters/AppFilters';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppDatepicker,
    AppFilters,
    AppInput,
    AppMultiselect,
  },
  data: () => ({
    form: {
      auth_id: null,
      created_at_from: null,
      created_at_to: null,
      email: null,
      last_name: null,
      name: null,
      person_id: null,
      username: null,
      team: []
    }
  }),
  beforeMount() {
    this.$store.dispatch('teamsStore/GET_TEAMS');
  },
  computed: {
    ...mapGetters({
      teamsList: 'teamsStore/TEAMS',
    })
  }
}
</script>